import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { auth } from "./config/firebase";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/dashboard/Dashboard";
import Users from "./views/common/Users";
import Profile from "./components/dashboard/Profile";
import NotFound from "./views/common/not_found/NotFound";
import LandingPage from "./components/landingPage/LandingPage";
import BusList from "./views/admin/BusCard/BusList";
import BusInfo from "./views/admin/BusCard/BusInfo";
import BookingDetails from "./views/common/BookingDetails";
import Report from "./components/Report/Report";
import Password from "./components/dashboard/Passward";
import BookNow from "./views/common/BookNow";

function App() {
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      console.log(user);
    });
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/users" element={user ? <Users /> : <Login />} />
        <Route path="/bus" element={user ? <BusList /> : <Login />} />
        <Route
          path="/bookings"
          element={user ? <BookingDetails /> : <Login />}
        />
        <Route
          path="/seatview"
          element={user ? <BusInfo withSideBar={true} /> : <Login />}
        />
        <Route path="/dashboard" element={user ? <Dashboard /> : <Login />} />
        <Route
          path="/settings/profile"
          element={user ? <Profile /> : <Login />}
        />
        <Route
          path="/book-now"
          element={user ? <BookNow /> : <Login />}
        />
        <Route
          path="/settings/password"
          element={user ? <Password /> : <Login />}
        />
        <Route path="/reports" element={user ? <Report /> : <Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
