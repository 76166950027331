import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppAppBar from "../utilities/AppBar/AppAppBar";
import getLPTheme from "./getLPTheme";
import Home from "../Home/Home";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Footer from "../utilities/Footer/Footer";
import HorizontalNonLinearStepper from "../seatView/HorizontalNonLinearStepper";
import { searchBusRoute, fetchAllBuses } from "../../services/busServices";
import { toast } from "react-toastify";
import format from "date-fns/format";

const defaultTheme = createTheme();

function LandingPage(params) {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const [item, setItem] = useState("");
  const [view, setView] = useState("home");
  const [title, setTitle] = useState("Our Routes");

  // Common
  const [loading, setLoading] = useState(true);

  // Route
  const [allBusses, setAllBusses] = useState([]);

  // Manage footer visibility
  const [showFooter, setShowFooter] = useState(false);

  const busData = async () => {
    try {
      setLoading(true);
      const res = await fetchAllBuses();
      console.log(res);
      setAllBusses(res);
      setLoading(false);
      setShowFooter(true); // Show footer once data is loaded
      console.log("in Home");
    } catch (error) {
      console.error("Error fetching bus details:", error.message);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowFooter(false);

    // Validation checks
    if (!pickup || !drop || !date) {
      toast.error("Please select pickup, drop, and date");
      return;
    }

    setLoading(true);

    // Check if date is valid
    const formattedDate = date ? format(new Date(date), "yyyy-MM-dd") : "";
    if (!formattedDate) {
      toast.error("Invalid date selected");
      setLoading(false);
      return;
    }

    console.log("Form submitted:", date, formattedDate);
    console.log(pickup, date, drop);

    try {
      const busRouteData = await searchBusRoute(pickup, drop, formattedDate);
      setAllBusses(busRouteData);
      setTitle("Search Result");
      setSeatSelect(false);
      setLoading(false);
      setShowFooter(true); // Show footer once search results are loaded

      // Further processing/rendering of bus route data
    } catch (error) {
      console.error("Error searching bus route:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    busData();
  }, []);

  // Hero
  const [pickup, setPickup] = React.useState("");
  const [drop, setDrop] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [seatSelect, setSeatSelect] = useState(true);

  const renderContent = () => {
    switch (view) {
      case "home":
        return (
          <Home
            setView={setView}
            setItem={setItem}
            loading={loading}
            handleSubmit={handleSubmit}
            setPickup={setPickup}
            setDrop={setDrop}
            setDate={setDate}
            pickup={pickup}
            drop={drop}
            date={date}
            allBusses={allBusses}
            title={title}
            seatSelect={seatSelect}
          />
        );

      case "about":
        return <About />;
      case "contact":
        return <Contact />;
      case "seatView":
        return (
          <HorizontalNonLinearStepper
            item={item}
            pickup={pickup}
            drop={drop}
            date={date}
          />
        );
      default:
        return <Home />;
    }
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} setView={setView} />
      <Box>{renderContent()}</Box>
      {showFooter && <Footer />}
    </ThemeProvider>
  );
}

export default LandingPage;
