import * as React from "react";
import { Box } from "@mui/material";

import SeatGrid from "./SeatGrid";

export default function SeatView({ item, userMode, pickup, date, drop }) {
  console.log("item", item);
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        padding: 1,
      })}
    >
      <SeatGrid
        item={item}
        userMode={userMode}
        pickup={pickup}
        drop={drop}
        date={date}
      />
    </Box>
  );
}
